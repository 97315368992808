
import { Grid } from "@mui/material";
import { Typography } from "@mui/material";
import { Stack } from "@mui/material";
import { Box } from "@mui/material";

import { createTheme, ThemeProvider } from '@mui/material/styles';

import { styled } from '@mui/material/styles';
import Paper from "@mui/material/Paper";

import { Divider } from "@mui/material";


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? 'black' : 'black',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function Footer() {


    
    const darkTheme= createTheme({
        palette: {
          mode: 'dark',
        },
      });

    const RealityReach = 'https://armeta-card-builder.appspot.com.storage.googleapis.com/assets/images/RealityReach(2).png'

    /* 'https://armeta-card-builder.appspot.com.storage.googleapis.com/assets/images/icons/Button_Email.png' */
    return (
        <>
            <ThemeProvider theme={darkTheme}>


                <footer>


                    <Grid container >

                        <Grid item sx={12} lg={12}>
                            <Item elevation={0}>
                                <Box  >
                                    <Stack direction="row"

                                        justifyContent="space-evenly"
                                        spacing={3} >

                                        <div>

                                            <a
                                                variant="body2"
                                                sx={{
                                                    color: 'white',
                                                    fontStyle: "italic"
                                                }}
                                                href="realityreach.com/privacy-policy" underline="none">
                                                Privacy Policy
                                            </a>


                                        </div>


                                        <div>
                                            <a
                                                variant="body2"
                                                sx={{
                                                    color: 'white',
                                                    fontStyle: "italic"
                                                }}
                                                href="realityreach.com/terms-of-use" underline="none">
                                                Terms of Use
                                            </a>
                                        </div>

                                        <div>
                                            <a
                                                sx={{
                                                    color: 'white',
                                                    fontStyle: "italic"
                                                }}
                                                href="realityreach.com/contact" underline="none">
                                                Contact Us
                                            </a>
                                        </div>


                                    </Stack>
                                </Box>
                            </Item>
                        </Grid>

                        <Grid item sx={12} lg={12}>

                            <Item alignItems='center'
                                elevation={0}
                                justifyContent='space-evenly'
                            >
                                <Box height='10vh' >
                                    <Stack
                                        spacing={1}
                                        justifyContent="center"
                                        direction='row'
                                        alignItems='center'
                                    >
                                        <img
                                            width='63.84'
                                            height='60'
                                            /* marginY='20px' */
                                            src={RealityReach}
                                        />
                                        <Typography
                                        >
                                            Copyright © 2022 Reality Reach, Inc. All rights reserved.
                                        </Typography>

                                    </Stack>
                                </Box>

                            </Item>
                        </Grid>
                    </Grid>





                </footer>
            </ThemeProvider>
        </>
    )
}
