import logo from './logo.svg';
import './App.css';
import { useState, useEffect } from 'react'
import { Stack, Typography, Box } from '@mui/material'
import { Grid } from "@mui/material";

import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import Footer from './components/pageFooter';
import Paper from '@mui/material/Paper';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'black' : 'black',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

/* require('./components/aframeComp') */

function App() {


  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });


  const [allow, setAllow] = useState(false)
  const [resolved, setResolved] = useState(false)
  const [status, setStatus] = useState("")
  const [experience,setExperience]=useState("main")

  var latitude 
  var longitude
  useEffect(() => {


    const compassCircle = document.querySelector(".compass-circle");
    const myPoint = document.querySelector(".my-point");
    const isIOS =
      navigator.userAgent.match(/(iPod|iPhone|iPad)/) &&
      navigator.userAgent.match(/AppleWebKit/);
    var compassAngle;

    function getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          // Success callback
          function (position) {
            showPosition(position);
            /*
              position is an object containing various information about
              the acquired device location:
      
              position = {
                  coords: {
                      latitude - Geographical latitude in decimal degrees.
                      longitude - Geographical longitude in decimal degrees. 
                      altitude - Height in meters relative to sea level.
                      accuracy - Possible error margin for the coordinates in meters. 
                      altitudeAccuracy - Possible error margin for the altitude in meters. 
                      heading - The direction of the device in degrees relative to north. 
                      speed - The velocity of the device in meters per second.
                  }
                  timestamp - The time at which the location was retrieved.
              }
              */
          },

          // Optional error callback
          function (error) {
            setStatus("Please enable the location services and refresh the browser");
            setAllow(false)
          }
        );

        //navigator.geolocation.getCurrentPosition(showPosition);
      } else {
        setStatus("Geolocation is not supported by this browser");
        setAllow(false)
        //  x.innerHTML = "Geolocation is not supported by this browser.";
      }
    }

    navigator.permissions
      .query({ name: "geolocation" })
      .then(function (result) {
        // Will return ['granted', 'prompt', 'denied']

        if (result.state == "granted")
          console.log(result.state);
      });

      

    function showPosition(position) {
      //locationDetermined = true;
      console.log(
        "Latitude: " +
        position.coords.latitude +
        "<br>Longitude: " +
        position.coords.longitude +
        "<br>Altitude: " +
        position.coords.altitude
      );
    }




    function init() {
      getLocation();
      startCompass();
      navigator.geolocation.getCurrentPosition(locationHandler);

      if (!isIOS) {
        window.addEventListener("deviceorientationabsolute", handler, true);
      }
      else {
        alert("This experience is not supported on Apple devices.")
        setAllow(false)
      }
    }

    function startCompass() {
      if (isIOS) {
        DeviceOrientationEvent.requestPermission()
          .then((response) => {
            if (response === "granted") {
              window.addEventListener("deviceorientation", handler, true);
            } else {
              alert("has to be allowed!");
            }
          })
          .catch(() => alert("not supported"));
      }
    }





    function handler(e) {
      setAllow(true)
      var compass = e.webkitCompassHeading || Math.abs(e.alpha - 360);
      compassCircle.style.transform = `translate(-50%, -50%) rotate(${-compass}deg)`;
      console.log(compass);
    }

    let pointDegree;

    function locationHandler(position) {
      setAllow(true)
      //const { latitude, longitude } = position.coords;

      latitude=position.coords.latitude
      longitude=position.coords.longitude
      //CheckDeployment(latitude,longitude)
      pointDegree = calcDegreeToPoint(latitude, longitude);
      console.log(latitude+" wiiw "+longitude)
      if (pointDegree < 0) {
        pointDegree = pointDegree + 360;
      }
    }

    function calcDegreeToPoint(latitude, longitude) {
      // Qibla geolocation
      const point = {
        lat: 21.422487,
        lng: 39.826206
      };

      const phiK = (point.lat * Math.PI) / 180.0;
      const lambdaK = (point.lng * Math.PI) / 180.0;
      const phi = (latitude * Math.PI) / 180.0;
      const lambda = (longitude * Math.PI) / 180.0;
      const psi =
        (180.0 / Math.PI) *
        Math.atan2(
          Math.sin(lambdaK - lambda),
          Math.cos(phi) * Math.tan(phiK) -
          Math.sin(phi) * Math.cos(lambdaK - lambda)
        );
      return Math.round(psi);
    }


    init();

    // See also https://github.com/aframevr/aframe/pull/4356


  }, [])
  

  function fnBrowserDetect() {

    let userAgent = navigator.userAgent;
    let browserName;

    if (userAgent.match(/chrome|chromium|crios/i)) {
      browserName = "chrome";
    } else if (userAgent.match(/firefox|fxios/i)) {
      browserName = "firefox";
    } else if (userAgent.match(/safari/i)) {
      browserName = "safari";
    } else if (userAgent.match(/opr\//i)) {
      browserName = "opera";
    } else if (userAgent.match(/edg/i)) {
      browserName = "edge";
    } else {
      browserName = "No browser detection";
    }
    if (browserName != "chrome") {
      setAllow(false)
      alert("Please Install Google Play Services for AR and Open the link in Google Chrome.")
      setStatus("Please Install" + <a href="https://play.google.com/store/apps/details?id=com.google.ar.core"> Google ARCore Services</a> + " and Open the link in Google Chrome.")
      setResolved(true)
    }
  }

  function CheckDeployment(latitude,longitude){
    //Finland: 60.145922,24.636342

    var FinlandCondition=(ReturnDistance(60.145922,24.636342)<500)
    var MainCondition=(ReturnDistance(16.72348981856074,74.2401591667697)<500)
    if(FinlandCondition){
      //alert(FinlandCondition)
      setExperience("custom")

      window.open("https://main-ar-experience.web.app/custom.html",'_self');
    }
    else if(MainCondition){
     // alert(MainCondition)
      setExperience("main")

      window.open("https://main-ar-experience.web.app/main.html",'_self');
    }
    else{
      window.open("https://main-ar-experience.web.app/fixed.html",'_self');
      setExperience("fixed")
    }


    function ReturnDistance(lat, lon) {
      var R = 6371000; // metres
      var lat2 = lat;
      var lat1 = latitude;
      var lon2 = lon;
      var lon1 = longitude;
      var φ1 = lat1 * Math.PI / 180; // φ, λ in radians
      var φ2 = lat2 * Math.PI / 180;
      var Δφ = (lat2 - lat1) * Math.PI / 180;
      var Δλ = (lon2 - lon1) * Math.PI / 180;

      var a =
          Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
          Math.cos(φ1) *
          Math.cos(φ2) *
          Math.sin(Δλ / 2) *
          Math.sin(Δλ / 2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

      var d = R * c; // in metres
      return d;
  }

  }


  function handleClick() {
    fnBrowserDetect()

    if (allow) {
      CheckDeployment()
      alert("Please Take Care of Your Surroundings.")
    }
    else {
      if (navigator.userAgent.match(/(iPod|iPhone|iPad)/) &&
        navigator.userAgent.match(/AppleWebKit/)) {
        setStatus("For iPhone users, location based AR experience through web-browser (WebXR) will debut with release of iOS 16. We’re sorry this experience is not supported currently, see you guys on 26th January")
        alert("For iPhone users, location based AR experience through web-browser (WebXR) will debut with release of iOS 16. We’re sorry this experience is not supported currently, see you guys on 26th January")
      }

    }



    setResolved(true)
  }

  return (<>
    {!resolved ? (<ThemeProvider

      theme={darkTheme}>
      <div className="App" >
        <header /* className="App-header" */>
          {/* <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a> */}
        </header>

        <body className='App-body'>

          <div className="compass">
            <div className="arrow"></div>
            <div className="compass-circle"></div>
            <div className="my-point"></div>
          </div>

          <Box width='100vw'>
            <Box height='70vh' width='100vw'></Box>
            <Typography

              style={{
                fontSize: 30, fontFamily: "Sarabun",
                color: 'white'
              }}>
              Independence Day AR  Experience</Typography>
            <Typography
              style={{
                fontSize: 30, fontFamily: "Sarabun",
                color: 'white'
              }}>An augmented reality experience for India's 75th Independence Day. {"\n"} <br></br>
              Jai Hind.
            </Typography>

            <Button
              variant='outlined'
              onClick={() => { handleClick() }}
              style={{
                borderColor: 'white',
                borderWidth: '2px',
                color: 'white'
              }}>Start</Button>

          </Box>
          <Footer />
        </body>

      </div>
    </ThemeProvider>)

      :

      allow ? (<>

      </>) :
        (<><ThemeProvider

          theme={darkTheme}>
          <div className="App" >
            <header /* className="App-header" */>
              {/* <a
              className="App-link"
              href="https://reactjs.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn React
            </a> */}
            </header>

            <body className='App-body'>

              <div className="compass">
                <div className="arrow"></div>
                <div className="compass-circle"></div>
                <div className="my-point"></div>
              </div>

              <Box height='100vh' width='100vw'>
                <Box height='70vh' width='100vw'></Box>
                <Typography
                  style={{
                    fontSize: 30, fontFamily: "Sarabun",
                    color: 'white'
                  }}>
                  Independence Day AR  Experience</Typography>
                <Typography
                  style={{
                    fontSize: 30, fontFamily: "Sarabun",
                    color: 'white'
                  }}>{status}</Typography>

                <Button
                  variant='outlined'
                  disabled
                  onClick={() => { handleClick() }}
                  style={{
                    borderColor: 'white',
                    borderWidth: '2px',
                    color: 'white'
                  }}>Start</Button>
              </Box>
              <Footer />
            </body>

          </div>
        </ThemeProvider>
        </>)

    }

  </>
  );
}

export default App;
